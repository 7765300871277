import * as yup from "yup";

const AccountSchema = yup.object().shape({
  name: yup.string().required(),
  company_name: yup.string().required(),
  company_address: yup.string().required(),
  phone: yup.string().required(),
  first_name: yup.string().required(),
  lastname: yup.string().required(),
  email: yup.string().email().required(),
  country_code: yup.object().required(),
  zip_code: yup.string().required(),
  city: yup.string().required(),
  state_code: yup.string().required(),
  tax_id: yup.string(),
  accounting: yup.string(),
  website: yup.string(),
  accounts: yup.object().required(),
  password: yup.string().required(),
  confirm_password: yup.string().required(),
  is_branded: yup.bool(),
  accept_terms: yup.bool().isTrue(),
  email_updates: yup.bool(),
});

export default AccountSchema;
