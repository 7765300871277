<template>
  <div class="create-account-form">
    <p class="create-account-form__fields-text">
      <span class="required-field">* </span> Required information
    </p>
    <div class="form">
      <div class="form__account" v-if="this.$route.name == 'CreateNewAccount'">
        <label class="create-account-form__label">
          {{ $t("createAccount.accounts") }}
          <span class="required-field" v-b-popover.hover.top="$t('createAccount.error.accounts')"
            >*</span
          >
        </label>
        <AccountIdsSearcher
          v-model="accountForm.accounts"
          :remountSearcher="remountSearcher"
          :enableEditing="true"
          :error="error.accounts"
          @change="selectAccount"
        />
      </div>
      <div class="form__contacts" v-if="accountForm.accounts">
        <label class="create-account-form__label">
          Contacts
          <span class="required-field" v-b-popover.hover.top="$t('createAccount.error.accounts')"
            >*</span
          >
        </label>
        <div class="create-account-form__border">
          <el-select v-model="selectedContacts" placeholder="Select a contact">
            <el-option
              v-for="item in filteredAccountContacts"
              :key="item.id"
              :label="`${item.first_name || ''} ${item.last_name || ''}`"
              :value="item.first_name"
              style="padding: 0px"
            >
              <div
                class="item-container"
                style="width: 100%; height: 100%"
                @click="selectedContact(item)"
              >
                <span
                  style="float: left; color: #15486f; font-family: 'Montserrat'; padding-left: 8px"
                  v-if="item.first_name || item.last_name"
                  >{{ item.first_name ? item.first_name.toUpperCase() : "" }}
                  {{ item.last_name ? item.last_name.toUpperCase() : "" }}</span
                >
                <span
                  style="
                    float: right;
                    font-size: 13px;
                    color: #606266 !important;
                    padding-right: 8px;
                  "
                  >{{ item.email || "N/A" }}</span
                >
              </div>
            </el-option>
          </el-select>
        </div>
      </div>
    </div>
    <div class="create-account-form__grid">
      <div class="create-account-form__group">
        <label class="create-account-form__label">
          {{ $t("createAccount.companyName") }}
          <span class="required-field" v-b-popover.hover.top="$t('createAccount.error.companyName')"
            >*</span
          >
        </label>
        <div
          class="create-account-form__border"
          :class="error.company_name === true ? 'missing-field' : ''"
        >
          <input
            type="text"
            class="create-account-form__input"
            :class="error.company_name === true ? 'missing-field' : ''"
            v-model.trim="accountForm.company_name"
            :minlength="inputLimits.company_name.min"
            :maxlength="inputLimits.company_name.max"
            tabindex="1"
          />
          <button class="tooltip-icon" type="button">
            <i
              class="ion ion-information-circle-outline"
              v-b-popover.hover.top="$t('createAccount.message.companyName')"
            ></i>
          </button>
        </div>
      </div>
      <div class="create-account-form__group">
        <label class="create-account-form__label">
          {{ $t("createAccount.companyAddress") }}
          <span
            class="required-field"
            v-b-popover.hover.top="$t('createAccount.error.companyAddress')"
            >*</span
          >
        </label>
        <div
          class="create-account-form__border"
          :class="error.company_address === true ? 'missing-field' : ''"
        >
          <input
            type="text"
            class="create-account-form__input"
            :class="error.company_address === true ? 'missing-field' : ''"
            v-model.trim="accountForm.company_address"
            :minlength="inputLimits.company_address.min"
            :maxlength="inputLimits.company_address.max"
            tabindex="2"
          />
          <button class="tooltip-icon" type="button">
            <i
              class="ion ion-information-circle-outline"
              v-b-popover.hover.top="$t('createAccount.message.companyAddress')"
            ></i>
          </button>
        </div>
      </div>

      <div class="create-account-form__group">
        <label class="create-account-form__label">
          {{ $t("createAccount.phoneNumber") }}
          <span class="required-field" v-b-popover.hover.top="$t('createAccount.error.phoneNumber')"
            >*</span
          >
        </label>
        <div
          class="create-account-form__border"
          :class="error.phone === true ? 'missing-field' : ''"
        >
          <input
            type="text"
            class="create-account-form__input"
            :class="error.phone === true ? 'missing-field' : ''"
            v-model.trim="accountForm.phone"
            :minlength="inputLimits.phone.min"
            :maxlength="inputLimits.phone.max"
            @input="assignFormattedNumber()"
            tabindex="3"
            ref="phoneInput"
          />
        </div>
      </div>

      <div class="create-account-form__group">
        <label class="create-account-form__label">
          {{ $t("createAccount.firstName") }}
          <span class="required-field" v-b-popover.hover.top="$t('createAccount.error.firstName')"
            >*</span
          >
        </label>
        <div
          class="create-account-form__border"
          :class="error.first_name === true ? 'missing-field' : ''"
        >
          <input
            type="text"
            class="create-account-form__input"
            :class="error.first_name === true ? 'missing-field' : ''"
            v-model.trim="accountForm.first_name"
            tabindex="4"
          />
        </div>
      </div>

      <div class="create-account-form__group">
        <label class="create-account-form__label">
          {{ $t("createAccount.lastName") }}
          <span class="required-field" v-b-popover.hover.top="$t('createAccount.error.lastName')"
            >*</span
          >
        </label>
        <div
          class="create-account-form__border"
          :class="error.lastname === true ? 'missing-field' : ''"
        >
          <input
            type="text"
            class="create-account-form__input"
            :class="error.lastname === true ? 'missing-field' : ''"
            v-model.trim="accountForm.lastname"
            tabindex="5"
          />
        </div>
      </div>

      <div class="create-account-form__group">
        <label class="create-account-form__label">
          {{ $t("createAccount.email") }}
          <span class="required-field" v-b-popover.hover.top="$t('createAccount.error.email')"
            >*</span
          >
        </label>
        <div
          class="create-account-form__border"
          :class="error.email === true ? 'missing-field' : ''"
        >
          <input
            type="email"
            class="create-account-form__input"
            :class="error.email === true ? 'missing-field' : ''"
            v-model.trim="accountForm.email"
            tabindex="6"
          />
          <button class="tooltip-icon" type="button">
            <i
              class="ion ion-information-circle-outline"
              v-b-popover.hover.top="$t('createAccount.message.email')"
            ></i>
          </button>
        </div>
      </div>

      <div class="create-account-form__group">
        <label for="countryCode" class="create-account-form__label">
          {{ $t("createAccount.countryCode") }}
          <span class="required-field" v-b-popover.hover.top="$t('createAccount.error.countryCode')"
            >*</span
          >
        </label>
        <Dropdown
          class="create-account-form__border"
          :class="error.country_code === true ? 'missing-field' : ''"
          v-model="accountForm.country_code"
          :options="countries"
          optionLabel="name"
          :filter="true"
          :showClear="true"
          tabindex="7"
        >
          <template #value="slotProps">
            <div class="country-item country-item-value" v-if="slotProps.value">
              <div>{{ slotProps.value.name }}</div>
            </div>
            <span v-else>
              {{ slotProps.placeholder }}
            </span>
          </template>
          <template #option="slotProps">
            <div class="country-item">
              <div>{{ slotProps.option.name }}</div>
            </div>
          </template>
        </Dropdown>
      </div>

      <div class="create-account-form__group">
        <label class="create-account-form__label">
          {{ $t("createAccount.zipCode") }}
          <span class="required-field" v-b-popover.hover.top="$t('createAccount.error.zipCode')"
            >*</span
          >
        </label>
        <div
          class="create-account-form__border"
          :class="error.zip_code === true ? 'missing-field' : ''"
        >
          <input
            type="text"
            class="create-account-form__input"
            :class="error.zip_code === true ? 'missing-field' : ''"
            v-model.trim="accountForm.zip_code"
            :minlength="inputLimits.zip_code.min"
            :maxlength="inputLimits.zip_code.max"
            tabindex="8"
          />
        </div>
      </div>

      <div class="create-account-form__group">
        <label class="create-account-form__label">
          {{ $t("createAccount.city") }}
          <span class="required-field" v-b-popover.hover.top="$t('createAccount.error.city')"
            >*</span
          >
        </label>
        <div
          class="create-account-form__border"
          :class="error.city === true ? 'missing-field' : ''"
        >
          <input
            type="text"
            :class="error.city === true ? 'missing-field' : ''"
            class="create-account-form__input"
            v-model.trim="accountForm.city"
            :minlength="inputLimits.city.min"
            :maxlength="inputLimits.city.max"
            tabindex="9"
          />
        </div>
      </div>

      <div class="create-account-form__group">
        <label class="create-account-form__label">
          {{ $t("createAccount.stateCode") }}
          <span class="required-field" v-b-popover.hover.top="$t('createAccount.error.stateCode')"
            >*</span
          >
        </label>
        <div
          class="create-account-form__border"
          :class="error.state_code === true ? 'missing-field' : ''"
        >
          <input
            type="text"
            class="create-account-form__input"
            :class="error.state_code === true ? 'missing-field' : ''"
            v-model.trim="accountForm.state_code"
            :minlength="inputLimits.state_code.min"
            :maxlength="inputLimits.state_code.max"
            tabindex="10"
          />
        </div>
      </div>

      <div class="create-account-form__group create-account-form__group--password">
        <div>
          <label class="create-account-form__label">
            {{ $t("createAccount.password") }}
            <span class="required-field" v-b-popover.hover.top="$t('createAccount.error.password')"
              >*</span
            >
          </label>
        </div>
        <div
          class="create-account-form__border"
          id="popover-target-restrictions"
          :class="error.password === true ? 'missing-field' : ''"
        >
          <input
            @focus="showPopover = true"
            @blur="showPopover = false"
            :type="typeNewPassword"
            class="create-account-form__input"
            :class="error.password === true ? 'missing-field' : ''"
            v-model.trim="accountForm.password"
            :minlength="inputLimits.password.min"
            :maxlength="inputLimits.password.max"
            name="new-password"
            id="new-password"
            tabindex="14"
          />
          <button class="tooltip-icon" type="button" @click="changeTypeOfInput('new-password')">
            <i :class="iconNewPassword"></i>
          </button>
        </div>
        <div class="message-error">
          <span v-show="passwordIsStrong && !passwordNotMatch"> * Enter a valid password </span>
          <span v-show="passwordNotMatch">
            <i class="ion ion-alert-circle-outline"></i>
            {{ $t("createAccount.error.passwordNotMatch") }}
          </span>
        </div>
        <PasswordPopover
          class="password-popover"
          :showPopover="showPopover"
          :restriction="restriction"
          :target="'popover-target-restrictions'"
        />
      </div>

      <div class="create-account-form__group create-account-form__group--password">
        <label class="create-account-form__label">
          {{ $t("createAccount.confirmPassword") }}
          <span
            class="required-field"
            v-b-popover.hover.top="$t('createAccount.error.confirmPassword')"
            >*</span
          >
        </label>
        <div
          class="create-account-form__border"
          :class="error.confirm_password === true ? 'missing-field' : ''"
        >
          <input
            :type="typeConfirmPassword"
            class="create-account-form__input"
            :class="error.confirm_password === true ? 'missing-field' : ''"
            v-model.trim="accountForm.confirm_password"
            :minlength="inputLimits.password.min"
            :maxlength="inputLimits.password.max"
            name="confirm-password"
            id="confirm-password"
            tabindex="15"
          />
          <button class="tooltip-icon" type="button" @click="changeTypeOfInput('confirm-password')">
            <i :class="iconConfirmPassword"></i>
          </button>
        </div>
      </div>

      <div class="create-account-form__group" v-if="this.$route.name == 'CreateNewAccount'">
        <div class="checkbox-container-branded">
          <Checkbox id="branded" v-model="accountForm.is_branded" :binary="true" />
          <label for="branded">Is this new account branded?</label>
        </div>
      </div>
    </div>

    <div class="create-account-checkboxes" v-if="this.$route.name !== 'CreateNewAccount'">
      <div class="checkbox-container mr-3">
        <Checkbox id="terms-conditions" v-model="accountForm.accept_terms" :binary="true" />
        <div
          for="terms"
          class="create-account-checkboxes__accept-terms"
          @click="openModalTerms"
          v-b-modal="'v-b-modal.modal-multi-2'"
        >
          {{ $t("createAccount.checkbox.terms") }}
          <span class="required-field" v-b-popover.hover.top="$t('createAccount.error.terms')"
            >*</span
          >
        </div>
      </div>
      <div class="checkbox-container">
        <Checkbox id="email-updates" v-model="accountForm.email_updates" :binary="true" />
        <label for="binary">{{ $t("createAccount.checkbox.updates") }}</label>
      </div>
    </div>
    <b-modal
      id="v-b-modal.modal-multi-2"
      hide-header
      hide-footer
      size="lg"
      no-close-on-backdrop
      centered
    >
      <TermsAndConditions
        @closeModalTerms="closeModalTerms"
        @acceptTerms="acceptTerms"
        @declineTerms="declineTerms"
      />
    </b-modal>
  </div>
</template>

<script>
/* eslint-disable */
// import { errorMessage } from "@/utils/ErrorMessages";
import PasswordPopover from "@/components/passwordValidationsPopover.vue";
import { countries } from "countries-list";
import Checkbox from "primevue/checkbox";
import Dropdown from "primevue/dropdown";
import TermsAndConditions from "@/views/TermsAndConditions/Index.vue";
import changeTypePasswords from "../../Extend/changeTypePasswords";
import AcceptTerms from "../../Extend/AcceptTerms";
import errorValidation from "../../Extend/ValidationCreateAccount";
import AccountIdsSearcher from "../../components/NewAccountIdsSearcher.vue";
import AccountSchema from "../../schemas/AccountSchema";
import FormatPhoneNumber from "@/Extend/FormatPhoneNumber";

export default {
  name: "CreateAccountInputs",
  props: {
    createAccountFromLogin: Boolean,
  },
  components: {
    Checkbox,
    Dropdown,
    TermsAndConditions,
    AccountIdsSearcher,
    PasswordPopover,
  },
  mixins: [changeTypePasswords, AcceptTerms, errorValidation, FormatPhoneNumber],
  data() {
    return {
      remountSearcher: 0,
      accountForm: {
        name: "Name",
        company_name: "",
        company_address: "",
        phone: "",
        first_name: "",
        lastname: "",
        email: "",
        country_code: "",
        zip_code: "",
        city: "",
        state_code: "",
        accounts: null,
        password: "",
        confirm_password: "",
        is_branded: false,
        accept_terms: false,
        email_updates: false,
        can_dispatch: true,
        filteredAccountIds: [{ sectionName: "Customers", options: [] }],
      },
      countries: null,
      error: {},
      showPopover: false,
      countriesList: [],
      phoneMask: "# (###) ###-####",
      selectedContacts: null,
      filteredAccountContacts: {},
      // Input limits
      inputLimits: {
        company_name: {
          min: 1,
          max: 150,
        },
        company_address: {
          min: 3,
          max: 150
        },
        phone: {
          min: 7,
          max: 30
        },
        zip_code: {
          min: 2,
          max: 20
        },
        city: {
          min: 2,
          max: 25
        },
        state_code: {
          min: 0,
          max: 50
        },
        password: {
          min: 8,
          max: 16
        }
      }
    };
  },
  watch: {
    accountForm: {
      deep: true,
      handler(newValue, oldValue) {
        this.$emit("input", this.accountForm);
      },
    },
    "accountForm.accounts.name": {
      handler(newValue, oldValue) {
        if (newValue !== oldValue) {
          this.accountForm.email = "";
          this.accountForm.first_name = "";
          this.accountForm.lastname = "";
          this.accountForm.phone = "";
          this.selectedContacts = null;
        }
      },
    },
  },
  created() {
    this.countriesList = Object.keys(countries).map((key) => countries[key]);
    const USAObject = this.countriesList.find((country) => country.name === "United States");
    this.countriesList = this.countriesList.filter((country) => country.name !== "United States");
    this.countriesList.unshift(USAObject);
    this.countries = this.countriesList;
    if (this.$route.name === "CreateNewAccount") {
      this.accountForm.accept_terms = true;
      this.accountForm.email_updates = true;
    }
  },
  methods: {
    assignFormattedNumber() {
      const formattedPhoneNumber = this.formatPhoneNumber(this.accountForm.phone);
      this.accountForm.phone = formattedPhoneNumber;
    },
    showPopoverTrigger() {
      this.showPopover = true;
    },
    async validateForm() {
      if (this.createAccountFromLogin) {
        delete AccountSchema.fields.accounts;
      }
      this.error = {};
      try {
        const passwordMatch = this.passwordsMatch();
        const passwordStrong = this.strongPassword();
        const isValid = await AccountSchema.validate(this.accountForm, {
          abortEarly: false,
        });
        if (!passwordMatch && !passwordStrong) {
          this.error.passwordRestrictionError = true;
        } else {
          this.error.passwordRestrictionError = false;
        }
        return isValid;
      } catch (error) {
        this.error = {};
        error.inner.forEach((element) => {
          this.error[element.path] = true;
        });
        if (Object.entries(this.error).length === 1 && this.error.accept_terms) {
          this.swal({
            title: this.$t("createAccount.errorTermsAndConditions"),
            icon: "warning",
            dangerMode: false,
          });
        }
        return false;
      }
    },
    selectAccount(account) {
      if (this.accountForm.accounts !== null) {
        this.getAccountsContact();
      }
      const shippingAddress = account.shipping_address;
      Object.keys(shippingAddress).forEach((key) => {
        if (key === "country") {
          this.accountForm.country_code = this.countries.find(
            (country) => country.name.toLowerCase() === shippingAddress.country.toLowerCase()
          );
        }
      });
      this.accountForm.company_address = shippingAddress.street;
      this.accountForm.zip_code = shippingAddress.postal_code;
      this.accountForm.state_code = shippingAddress.state;
      this.accountForm.city = shippingAddress.city;
    },
    async getAccountsContact() {
      if (this.accountForm.accounts.id !== undefined) {
        const response = await this.$store.dispatch(
          "account/getAccountContacts",
          this.accountForm.accounts.id
        );
        this.filteredAccountContacts = [];
        this.filteredAccountContacts = response;
      }
    },
    selectedContact(payload) {
      this.accountForm.email = payload.email;
      this.accountForm.first_name = payload.first_name;
      this.accountForm.lastname = payload.last_name;
      this.accountForm.phone = payload.phone;
    },
  },
};
</script>

<style lang="scss" scoped>
@import "@/assets/scss/components/Modal.scss";

.create-account-contacts {
  display: flex;
}
.create-account-form {
  &__fields-text {
    text-align: left;
  }
  &__grid {
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    justify-content: space-between;
  }
  &__group {
    margin-right: 3%;
    margin-left: 3%;
    &--password {
      position: relative;
      @media (max-width: 680px) {
        margin: 10px 3%;
      }
    }
  }
  &__label {
    @include label-input-information;
  }
  &__border {
    @include input-information;
    justify-content: center;
    align-items: center;
  }
  &__input {
    @include input-password;
  }
  @media (max-width: 900px) {
    &__grid {
      grid-template-columns: repeat(2, 1fr);
    }
  }
  @media (max-width: 680px) {
    width: 100%;
    &__grid {
      grid-template-columns: repeat(1, 1fr);
    }
  }
}
.create-account-checkboxes {
  @extend %flex-stretch;
  margin-top: 10px;
  width: 100%;
  display: flex;
  justify-content: center;
  .checkbox-container {
    display: flex;
    flex-direction: row;
    justify-content: center;
    text-align: left;
    margin: 5px 0px 0px 5px;
    label {
      margin: 0px 0px 0px 10px;
      color: $color-primary-company;
    }
  }
  &__accept-terms {
    margin: 0px 0px 0px 15px;
    &:hover {
      text-decoration: underline;
      color: rgb(0, 0, 168);
    }
  }
}
.checkbox-container-branded {
  display: flex;
  margin-top: 25px;
  label {
    margin-left: 10px;
  }
}
.tooltip-icon {
  @include icon-input;
  padding: 0;
  margin: 0;
}
.required-field {
  color: $color-error;
  margin-left: 5px;
}
.message-error {
  position: absolute;
  color: $color-error;
  margin-left: 3px;
  bottom: -12px;
  font-size: 0.8rem;
}

.missing-field {
  background-color: $missing-fields;
}

.form {
  display: flex;
  width: 100%;
  justify-content: space-around;
  &__account {
    display: flex;
    flex-direction: column;
    width: 33%;
  }
  &__contacts {
    display: flex;
    flex-direction: column;
    width: 33%;
  }
}

.form__account {
  & ::v-deep .autocomplete {
    margin: 10px 0px;
    & .el-form .el-form-item .el-input__inner{
      height: 40px;
      background-color: #fff;
      border: 2px solid rgb(221, 221, 221);
      border-radius: 15px;
      opacity: 1;
      color: var(--color-primary-company);
      box-shadow: 5px 4px 5px -2px #c4c4c4;
      font-family: "Montserrat";
      outline: none;
      font-size: 14px;
      justify-content: center;
      align-items: center;
    }

    &__select--option {
      color: $color-primary-company;
    }
  }
}

::v-deep {
  ///****Modified primeVue styles-dropdown****\\\\
  .p-dropdown-panel .p-dropdown-header .p-dropdown-filter {
    font-family: $font-family-portal;
    color: $color-primary-company;
    margin-right: 0;
  }

  .p-dropdown:not(.p-disabled):hover {
    border: 2px solid rgb(221, 221, 221);
  }

  .p-dropdown:not(.p-disabled).p-focus {
    border: 2px solid rgb(221, 221, 221);
  }

  .p-dropdown {
    align-items: center;
  }

  .p-dropdown .p-dropdown-panel {
    max-width: 90%;
  }

  .p-dropdown .p-dropdown-label {
    font-family: $font-family-portal;
    color: $color-primary-company;
    text-align: left;
    font-size: 14px;
    padding-left: 20px;
  }

  .p-dropdown-items {
    font-family: $font-family-portal;
    color: $color-primary-company;
  }

  .p-dropdown-panel .p-dropdown-items .p-dropdown-empty-message {
    color: $color-primary-company;
  }

  ///****Modified primeVue styles-Checkbox****\\\\
  .p-checkbox .p-checkbox-box {
    padding: 10px;
    border-radius: 6px;
    border: 1px solid $color-border-container;
  }

  .p-checkbox .p-checkbox-box.p-highlight {
    background: $color-border-container;
    background-color: $color-border-container;
    border-color: $color-border-container;
    &:hover {
      background: $color-border-container;
      background-color: $color-border-container;
      border-color: $color-border-container;
    }
  }

  .p-checkbox:not(.p-checkbox-disabled) .p-checkbox-box.p-focus {
    border-color: $color-border-container;
    box-shadow: none;
  }

  .p-checkbox:not(.p-checkbox-disabled) .p-checkbox-box:hover {
    border-color: $color-border-container;
  }

  .search-input .search-input__main-content {
    flex-basis: 95%;
  }

  .el-select {
    width: 100%;
    padding: 0px;
  }

  .el-input__inner {
    border: none;
    height: 20px;
  }

  .el-input__suffix {
    display: flex;
    align-items: center;
  }


  .el-select-dropdown
  .el-scrollbar
  .el-select-dropdown__wrap
  .el-scrollbar__view
  .el-select-dropdown__item.selected {
  /* color: $color-primary-company; */
  color: red;
  }

  .popover {
    &__item {
      display: flex;
      flex-direction: row;
      align-items: center;
    }
    &__icon {
      margin-right: 5px;
      font-size: 18px;
      &-false {
        color: $color-error;
      }
      &-true {
        color: $color-blue-hover;
      }
    }
  }
}
</style>
